import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Snackbar, SnackbarType, Typography } from '../../../../atoms';
import { AccountLayout } from '../../../../layout';

import useAutocompleteServices from '../../../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';
import useMyUserDetails from '../../../../../api/auth/me/useMyUserDetails';
import useCreateDevelopment, {
  CreateDevelopmentResponse,
} from '../../../../../api/developments/useCreateDevelopment';

import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import {
  useAddressAutocomplete,
  useSignOut,
  useSnackbar,
} from '../../../../../hooks';
import { navigate } from '../../../../../utils/dom';
import { APP_ROUTES } from '../../../../../config/app';

import { SnackbarContent } from '../../property/edit/components';
import MyAccountSideBar from '../../MyAccountSideBar/MyAccountSideBar';
import CreatePropertyForm from '../CreatePropertyForm/CreatePropertyForm';
import { CreatePropertyFormFieldValues } from '../types';

const createPropertyFormDefaultValues: CreatePropertyFormFieldValues = {
  title: '',
  geometry: { lat: 51.5072, lng: 0.1276 }, // London
  address: '',
  postcode: '',
  city: '',
  country: '',
};

export default function CreatePropertyPage() {
  const authToken = useAuthToken();
  const signOut = useSignOut();

  const { snackbarState, displaySnackbar, hideSnackbar } = useSnackbar();

  const { data: myUserDetails } = useMyUserDetails({
    authToken,
  });
  const orgId = myUserDetails?.organization.organizationId ?? 0;

  const formSubmitHandlerOnMutate = () => {
    displaySnackbar(
      SnackbarType.SUCCESS,
      <SnackbarContent text="Creating property. Please wait..." />
    );
  };

  const { mutate: createDevelopment, status: createDevelopmentStatus } =
    useCreateDevelopment({
      queryConfig: { onMutate: formSubmitHandlerOnMutate },
    });

  const formSubmitHandlerOnSuccess = (data: CreateDevelopmentResponse) => {
    displaySnackbar(
      SnackbarType.SUCCESS,
      <SnackbarContent text="Property created! Redirecting..." />
    );

    void navigate(
      APP_ROUTES.propertyManagementEditDevelopmentBasicInformation({
        orgId,
        developmentId: data.data.development.development_id,
      })
    );
  };
  const formSubmitHandlerOnError = (error: Error) => {
    displaySnackbar(
      SnackbarType.ERROR,
      <SnackbarContent text={error.message} />
    );
  };

  const formSubmitHandler: SubmitHandler<CreatePropertyFormFieldValues> = (
    data
  ) => {
    createDevelopment(
      {
        authToken,
        orgId,
        title: data.title.trim(),
        geometry: data.geometry,
        address: data.address.trim(),
        postcode: data.postcode.trim(),
        city: data.city.trim(),
        country: data.country.trim(),
      },
      {
        onSuccess: formSubmitHandlerOnSuccess,
        onError: formSubmitHandlerOnError,
      }
    );
  };

  const {
    control: createPropertyFormControl,
    handleSubmit: createPropertyFormSubmitFactory,
    getValues: createPropertyFormGetValues,
    setValue: createPropertyFormSetValue,
    resetField: createPropertyFormResetField,
  } = useForm<CreatePropertyFormFieldValues>({
    defaultValues: createPropertyFormDefaultValues,
  });

  const createPropertyFormSubmit =
    createPropertyFormSubmitFactory(formSubmitHandler);

  const mapRef = React.createRef();
  const mapsRef = React.createRef();
  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });
  const {
    setInputValue,
    predictions: autocompletePredictions,
    selectedAutocompletePrediction,
    setSelectedAutocompletePrediction: selectAutocompletePrediction,
    getPlaceDetails,
    placeDetails,
  } = useAddressAutocomplete({
    autocompleteService,
    placesService,
  });

  React.useEffect(() => {
    if (selectedAutocompletePrediction?.place_id) {
      getPlaceDetails(selectedAutocompletePrediction.place_id);
    }
  }, [selectedAutocompletePrediction?.place_id]);

  return (
    <>
      <AccountLayout
        leftHandSide={
          <MyAccountSideBar
            userData={myUserDetails?.user}
            onSignOutButtonClick={() => signOut()}
          />
        }
        rightHandSide={
          <CreatePropertyForm
            defaultValues={createPropertyFormDefaultValues}
            formControl={createPropertyFormControl}
            formSubmit={createPropertyFormSubmit}
            formSubmitIsLoading={createDevelopmentStatus === 'loading'}
            geometry={
              createPropertyFormGetValues('geometry') ??
              createPropertyFormDefaultValues.geometry
            }
            setGeometry={(geometry) => {
              // Should call both resetField() and setValue() otherwise the
              // form won't re-render and the map section won't update as
              // expected.
              createPropertyFormResetField('geometry');
              createPropertyFormSetValue('geometry', geometry);
            }}
            selectedPlaceDetails={placeDetails}
            setAddressTexts={({ address, postcode, city, country }) => {
              createPropertyFormSetValue('address', address);
              createPropertyFormSetValue('postcode', postcode);
              createPropertyFormSetValue('city', city);
              createPropertyFormSetValue('country', country);
            }}
            mapRef={mapRef}
            mapsRef={mapsRef}
            addressAutocompleteProps={{
              setInputValue,
              autocompletePredictions,
              selectedAutocompletePrediction,
              selectAutocompletePrediction,
            }}
          />
        }
        breadcrumbsContent={
          <Typography
            color="gray"
            colorVariant="dark"
            fontSize={20}
            component="span"
          >
            Create a property
          </Typography>
        }
      />

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        <>{snackbarState.content}</>
      </Snackbar>
    </>
  );
}
