import * as React from 'react';
import { Box, FormControlLabel, Switch } from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { ClickEventValue } from 'google-map-react';
import { Button, Spacer, TextField, Typography } from '../../../../atoms';
import {
  AddressAutocomplete,
  AddressAutocompleteProps,
} from '../../../../molecules';
import MapClickable from '../../../../organisms/Map/MapClickable';

import {
  getCityFromPlace,
  getCountryFromPlace,
  getGeometryFromPlace,
  getPostcodeFromPlace,
} from '../../../../../api/googleMaps/gcpPlaces/utils';
import { PlaceResult } from '../../../../../api/googleMaps/gcpPlaces/types';

import { defaultLatLng } from '../../../../../config/search/globals';
import { ScreenType } from '../../../../../config';

import { LatLngCoords } from '../../../../../utils/geolocation';
import useIsScreenType from '../../../../../utils/dom/useIsScreenType';
import { formatNumber } from '../../../../../utils/number';

import { CreatePropertyFormFieldValues } from '../types';
import useStyles from './CreatePropertyForm.styles';

export interface CreatePropertyFormProps {
  defaultValues: CreatePropertyFormFieldValues;
  formControl: Control<CreatePropertyFormFieldValues>;
  formSubmit: (e: React.FormEvent<HTMLElement>) => void;
  formSubmitIsLoading: boolean;

  geometry: LatLngCoords | undefined;
  setGeometry: (geometry: LatLngCoords) => void;
  selectedPlaceDetails: PlaceResult | null;
  setAddressTexts: (props: {
    address: string;
    postcode: string;
    city: string;
    country: string;
  }) => void;
  mapRef: React.RefObject<unknown>;
  mapsRef: React.RefObject<unknown>;
  addressAutocompleteProps: AddressAutocompleteProps;
}

function formatCoord(coord: number): string {
  return formatNumber(coord, {
    decimalPlaces: 4,
  });
}

export default function CreatePropertyForm({
  defaultValues,
  formControl,
  formSubmit,
  formSubmitIsLoading,
  geometry,
  setGeometry,
  selectedPlaceDetails,
  setAddressTexts,
  mapRef,
  mapsRef,
  addressAutocompleteProps,
}: CreatePropertyFormProps) {
  const isMobile = useIsScreenType(ScreenType.TABLET_PORTRAIT);
  const classes = useStyles({ isMobile });

  // In free text mode, the autocomplete address field is disabled.
  const [freeTextMode, setFreeTextMode] = React.useState(false);

  const formattedGeometry = geometry
    ? { lat: formatCoord(geometry.lat), lng: formatCoord(geometry.lng) }
    : { lat: 'Unknown', lng: 'Unknown' };

  const handleMapClick = (e: ClickEventValue) => {
    if (freeTextMode) {
      setGeometry({
        lat: e.lat,
        lng: e.lng,
      });
    }
  };

  const selectedPlaceDetailsId = selectedPlaceDetails?.place_id;
  React.useEffect(() => {
    if (!freeTextMode && selectedPlaceDetailsId && selectedPlaceDetails) {
      const { location: selectedPlaceLocationGeometry } =
        getGeometryFromPlace(selectedPlaceDetails);

      setGeometry(selectedPlaceLocationGeometry);
      setAddressTexts({
        address: selectedPlaceDetails.formatted_address ?? '',
        postcode: getPostcodeFromPlace(selectedPlaceDetails),
        city: getCityFromPlace(selectedPlaceDetails),
        country: getCountryFromPlace(selectedPlaceDetails),
      });
    }
  }, [freeTextMode, selectedPlaceDetailsId]);

  return (
    <form onSubmit={formSubmit}>
      <Box className={classes.inputsCtn}>
        <Controller<CreatePropertyFormFieldValues>
          name="title"
          control={formControl}
          defaultValue={defaultValues?.title ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="Property name*"
              labelClassName={classes.formTitle}
              placeholder="A name is required to create a development"
              fullWidth
              disabled={formSubmitIsLoading}
            />
          )}
        />

        <Typography className={classes.sectionTitle}>
          Development location
        </Typography>

        <Box className={classes.formFieldsCtn}>
          <Box className={classes.formFields}>
            <AddressAutocomplete
              {...addressAutocompleteProps}
              className={classes.addressAutocompleteInput}
              disabled={formSubmitIsLoading || freeTextMode}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={freeTextMode}
                  onChange={() => setFreeTextMode((prev) => !prev)}
                  color="primary"
                  disabled={formSubmitIsLoading}
                />
              }
              label="Enter address manually instead"
            />

            <Controller<CreatePropertyFormFieldValues>
              name="address"
              control={formControl}
              defaultValue={defaultValues?.address ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Address"
                  fullWidth
                  disabled={formSubmitIsLoading || !freeTextMode}
                />
              )}
            />
            <Controller<CreatePropertyFormFieldValues>
              name="postcode"
              control={formControl}
              defaultValue={defaultValues?.postcode ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Postcode"
                  fullWidth
                  disabled={formSubmitIsLoading || !freeTextMode}
                />
              )}
            />
            <Controller<CreatePropertyFormFieldValues>
              name="city"
              control={formControl}
              defaultValue={defaultValues?.city ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="City"
                  fullWidth
                  disabled={formSubmitIsLoading || !freeTextMode}
                />
              )}
            />
            <Controller<CreatePropertyFormFieldValues>
              name="country"
              control={formControl}
              defaultValue={defaultValues?.country ?? ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Country"
                  fullWidth
                  disabled={formSubmitIsLoading || !freeTextMode}
                />
              )}
            />
          </Box>
          <Box className={classes.map}>
            <Typography>Latitude: {formattedGeometry.lat}</Typography>
            <Typography>Longitude: {formattedGeometry.lng}</Typography>
            <Typography>
              {freeTextMode
                ? `Click on the map to change coordinates`
                : `Select an address in the Address search to change coordinates`}
            </Typography>
            <Box height={isMobile ? 250 : '100%'}>
              <MapClickable
                handleClick={handleMapClick}
                mapConfig={{
                  center: geometry ?? defaultLatLng,
                  zoom: 16,
                  hoverDistance: 24,
                }}
                mapRef={mapRef}
                mapsRef={mapsRef}
                pinsData={
                  geometry ? [{ lat: geometry.lat, lng: geometry.lng }] : []
                }
              />
            </Box>
          </Box>
        </Box>

        <Spacer y={3.25} />
        <Typography align="center">
          If address was manually inserted, don&#39;t forget to set your pin on
          the map.
        </Typography>

        <Spacer y={2} />
        <Box className={classes.submitBtnCtn}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={formSubmitIsLoading}
            onClick={formSubmit}
            roundedLevel="high"
          >
            CREATE PROPERTY
          </Button>
        </Box>
      </Box>
    </form>
  );
}
