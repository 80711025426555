import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    inputsCtn: ({ isMobile }) => {
      return {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: isMobile ? theme.spacing(2.5, 2) : theme.spacing(2.5, 0),
      };
    },

    sectionTitle: {
      fontWeight: 700,
      color: theme.palette.gray.contrastText,
    },

    formFieldsCtn: {
      display: 'flex',
      flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
      gap: theme.spacing(2.25),
    },

    formFields: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.25),
      flex: 1,
    },

    map: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.25),
      flex: 1,
    },

    submitBtnCtn: {
      display: 'flex',
      justifyContent: 'center',
    },

    formTitle: {
      fontWeight: 700,
    },

    addressAutocompleteInput: {
      height: '3.5rem',
    },
  })
);

export default useStyles;
