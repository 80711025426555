import * as React from 'react';
import Layout from '../../../components/layout/CommonLayout/Layout';
import SEO from '../../../components/atoms/SEO';
import GenericPage from '../../../components/layout/CommonLayout/GenericPage';

import { CreatePropertyPage as CreatePropertyPageComponent } from '../../../components/pages/my-account/create';

export default function CreatePropertyPage() {
  return (
    <Layout>
      <SEO title="Create new property" />
      <GenericPage>
        <CreatePropertyPageComponent />
      </GenericPage>
    </Layout>
  );
}
