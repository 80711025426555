import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import pvtFetcher, { PvtFetcherBaseProps } from '../utils/pvtFetcher';
import API_ENDPOINTS from '../endpoints';
import {
  DevelopmentActivityInDb,
  DevelopmentV2,
  FlatInDb,
} from '../property/types';

export interface CreateDevelopmentProps extends PvtFetcherBaseProps {
  title: string;
  geometry: {
    lat: number;
    lng: number;
  };
  address?: string;
  postcode?: string;
  city?: string;
  country?: string;
}

export interface CreateDevelopmentResponse {
  data: {
    development: DevelopmentV2;
    flats: FlatInDb[];
  };
  meta: { activity: DevelopmentActivityInDb };
}

export async function createDevelopment({
  authToken,
  orgId,
  title,
  geometry,
  address,
  postcode,
  city,
  country,
}: CreateDevelopmentProps): Promise<CreateDevelopmentResponse> {
  const url = API_ENDPOINTS.CREATE_DEVELOPMENT;

  const requestBody = {
    title,
    geometry,
    address,
    postcode,
    city,
    country,
  };

  return pvtFetcher<CreateDevelopmentResponse>(url, {
    authToken,
    orgId,
    method: 'POST',
    body: JSON.stringify(requestBody),
  });
}

export interface UseCreateDevelopmentProps {
  queryConfig?: UseMutationOptions<
    CreateDevelopmentResponse,
    Error,
    CreateDevelopmentProps
  >;
}

export default function useCreateDevelopment({
  queryConfig,
}: UseCreateDevelopmentProps = {}) {
  return useMutation(createDevelopment, queryConfig);
}
